/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChartData, ChartOptions } from 'chart.js';

import { theme } from '../../../theme';

export function getData(
  data: number[],
  labels: any[],
  averageDirection: 'horizontal' | 'vertical',
  isGoodAndBad?: boolean | null
): ChartData<'line', number[], string> {
  const getLabel = () => {
    switch (isGoodAndBad) {
      case true:
        return [[labels[0], '<- Bad'], ...labels.slice(1, labels.length - 2), [labels[labels.length - 1], 'Good ->']];
      case false:
        return [[labels[0], '<- Good'], ...labels.slice(1, labels.length - 2), [labels[labels.length - 1], 'Bad ->']];
      default:
        return labels;
    }
  };

  return {
    labels: labels.length > 0 ? getLabel() : [],
    datasets: [
      {
        data,
        fill: true,
        borderWidth: 3,
        borderColor: '#9159F7',
        backgroundColor: 'rgba(121, 100, 255, 0.22)',
        pointRadius: context => {
          const index = context.dataIndex;
          return data[index] === 0 ? 0 : averageDirection === 'horizontal' ? 3 : 0;
        },
        tension: 0.1
      }
    ]
  };
}

export const getOptions = (
  average: { value: number; direction: 'horizontal' | 'vertical' },
  condition: number,
  yAxisName?: string
): ChartOptions<'line'> => ({
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.5
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      borderWidth: 1,
      displayColors: false,
      titleColor: theme.palette.grey[500],
      bodyFont: { size: 14, weight: 700 },
      titleFont: { size: 12, weight: 400 },
      bodyColor: theme.palette.primary.main,
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.common.white,
      callbacks: {
        title: tooltipItems => {
          return `${tooltipItems[0]?.label}`;
        },
        label: tooltipItem => {
          return `Score: ${tooltipItem?.formattedValue}`;
        }
      }
    },
    title: {
      display: false
    },
    annotation: {
      annotations: [
        {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          scaleID: average.direction === 'horizontal' ? 'y' : 'x',
          value: average?.value,
          borderWidth: average.direction === 'horizontal' ? 1 : 2,
          borderColor: average.direction === 'horizontal' ? theme.palette.grey[500] : theme.palette.common.black,
          label: {
            content: average?.value ? 'Average' : '',
            display: true,
            position: average.direction === 'horizontal' ? 'start' : 'end',
            backgroundColor: 'transparent',
            color: average.direction === 'horizontal' ? theme.palette.grey[500] : theme.palette.common.black,
            xAdjust: average.direction === 'horizontal' ? 0 : -24,
            yAdjust: average.direction === 'horizontal' ? -10 : 0,
            padding: 0,
            font: {
              family: 'Manrope',
              size: 11,
              weight: 700,
              lineHeight: '14px'
            }
          }
        },
        {
          drawTime: 'afterDatasetsDraw',
          type: 'line',
          scaleID: 'x',
          value: condition,
          borderWidth: 2,
          borderColor: theme.palette.grey[500],
          borderDash: [6],
          label: {
            content: 'Condition',
            display: true,
            position: 'start',
            backgroundColor: 'transparent',
            color: theme.palette.grey[500],
            xAdjust: 28,
            padding: 0,
            font: {
              family: 'Manrope',
              size: 11,
              weight: 700,
              lineHeight: '14px'
            }
          }
        }
      ]
    }
  },
  scales: {
    x: {
      grid: {
        display: false,
        color: theme.palette.grey[200],
        lineWidth: 2,
        tickColor: 'transparent'
      },
      ticks: {
        maxRotation: 0,
        maxTicksLimit: 6,
        color: theme.palette.grey[500],
        font: {
          family: 'Manrope',
          size: 10,
          weight: 700
        }
      },
      afterBuildTicks(scale) {
        const ticks = scale.ticks || [];
        // eslint-disable-next-line no-param-reassign
        scale.ticks = [...ticks, ticks[ticks.length - 1]];
      }
    },
    y: {
      title: {
        display: !!yAxisName,
        text: yAxisName,
        color: theme.palette.grey[400],
        font: {
          family: 'Manrope',
          size: 10,
          weight: 500
        }
      },
      grid: {
        display: false
      },
      ticks: {
        maxTicksLimit: 6,
        color: theme.palette.grey[500],
        font: {
          family: 'Manrope',
          size: 10,
          weight: 700
        }
      }
    }
  }
});
