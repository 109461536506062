import React, { useEffect } from 'react';

import { Radio as MUIRadio, useTheme } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Text } from '../../Text/Text';

export interface RadioProps {
  state: string | undefined;
  setState: (state: string) => void;
  options: {
    value: string;
    label?: string;
    disabled?: boolean;
  }[];
  formTitle?: string;
  disabled?: boolean;
  defaultValue?: string;
  direction?: 'row' | 'column';
}

export const Radio = (props: RadioProps) => {
  const { state, setState, options, formTitle, disabled, defaultValue, direction = 'column' } = props;

  const { palette } = useTheme();

  const row = direction === 'row';

  const labelColor = (value: string) =>
    state === value && palette.primary.main ? palette?.grey[800] : palette?.grey[600];

  useEffect(() => {
    const setDefaultValue = () => defaultValue && setState(defaultValue);

    setDefaultValue();
  }, []);

  return (
    <FormControl>
      {formTitle && <Text type="small" text={formTitle} color={palette.grey?.[500]} />}
      <RadioGroup value={state} defaultValue={defaultValue} row={row}>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option.value}
            control={<MUIRadio disabled={disabled} />}
            data-testid={`Radio${option.value}`}
            disabled={option.disabled}
            label={
              option.label && (
                <Text
                  color={labelColor(option.value)}
                  text={option.label}
                  type={state === option.value ? 'bodyBold' : 'body'}
                  margin="0"
                />
              )
            }
            onChange={() => setState(option.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
