import React, { ReactNode } from 'react';

import { ContentCopyOutlined } from '@mui/icons-material';
import { Variant } from '@mui/material/styles/createTypography';
import { IconButton, Typography, TypographyProps } from '@mui/material';

import { typographyOptions } from '../../theme/typography';
import { theme } from '../../theme';

export type ContrastTypes = 'low' | 'medium' | 'high';

export type TextTypes = 'h1' | 'h2' | 'h3' | 'button' | 'small' | 'body' | 'bodyBold' | 'tiny' | 'tinyBold';

export interface TextProps extends Omit<TypographyProps, 'variant'> {
  text: ReactNode;
  type?: TextTypes;
  includeCopy?: boolean;
  contrast?: ContrastTypes;
  component?: React.ElementType;
}

function getTextType(type: TextTypes | undefined): Variant {
  switch (type) {
    case 'h1':
      return 'h1';

    case 'h2':
      return 'h2';

    case 'h3':
      return 'h3';

    case 'button':
      return 'button';

    case 'small':
      return 'h5';

    case 'body':
      return 'body1';

    case 'bodyBold':
      return 'body2';

    case 'tiny':
      return 'subtitle1';

    case 'tinyBold':
      return 'subtitle2';

    default:
      return 'body1';
  }
}

export const Text = (props: TextProps) => {
  const {
    text,
    type,
    color,
    contrast,
    includeCopy = false,
    overflow = 'hidden',
    whiteSpace = 'pre-line',
    textOverflow = 'ellipsis',
    component = 'span',
    ...otherProps
  } = props;

  const getContrastColor = (type: TextTypes | undefined, contrast: ContrastTypes | undefined) => {
    const typographyType = typographyOptions[getTextType(type)];
    const contrasts = typographyType?.contrasts as Record<ContrastTypes, string>;

    switch (contrast) {
      case 'low':
        return contrasts?.low;
      case 'medium':
        return contrasts?.medium;
      case 'high':
        return contrasts?.high;
      default:
        return typographyType?.color || '';
    }
  };

  const copyToClipboard = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    navigator?.clipboard?.writeText(text as string);
  };

  return (
    <Typography
      overflow={overflow}
      component={component}
      whiteSpace={whiteSpace}
      textOverflow={textOverflow}
      variant={getTextType(type)}
      sx={{
        position: 'relative',
        color: color || getContrastColor(type, contrast),

        '.MuiButtonBase-root': {
          top: 4,
          right: 4,
          padding: 0,
          width: '28px',
          height: '28px',
          display: 'none',
          borderRadius: '6px',
          position: 'absolute',
          color: theme?.palette?.grey[400],
          background: theme?.palette?.grey[100],
          border: `2px solid ${theme?.palette?.grey[300]}`,

          '.MuiSvgIcon-root': { margin: '3px auto', opacity: 0.7, width: '18px', height: '18px' }
        },

        ':hover': { '.MuiButtonBase-root': { display: 'block' } }
      }}
      {...otherProps}
    >
      {text}
      {includeCopy && (
        <IconButton size="small" onClick={e => copyToClipboard(e)}>
          <ContentCopyOutlined />
        </IconButton>
      )}
    </Typography>
  );
};
