import { CircularProgress, Stack, styled } from '@mui/material';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@mui/icons-material';

import { StyledButton, StyledContainer } from '../lib';

export const DesktopContainer = styled(StyledContainer)(() => ({
  gap: 0,
  padding: 0,
  width: '100%',
  height: '100vh',
  borderRadius: 0,
  minWidth: '1000px',
  flexDirection: 'row',

  '@media (max-width: 1000px)': {
    minWidth: '650px'
  }
}));

interface SidebarContainerProps {
  iconsOnly?: boolean;
}

export const SidebarContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'iconsOnly'
})<SidebarContainerProps>(({ iconsOnly }) => ({
  height: '100%',
  minHeight: '100vh',
  width: iconsOnly ? '80px' : '240px',
  textAlign: iconsOnly ? 'center' : 'left',
  alignItems: iconsOnly ? 'center' : 'left',
  borderRadius: 0,
  gap: '16px',
  padding: '16px',
  transition: '0.1s',

  img: { cursor: 'pointer', margin: iconsOnly ? '18px auto 30px' : '0 auto' }
}));

export const SidebarCloseIcon = styled(KeyboardArrowLeftRounded)(({ theme }) => ({
  margin: '15px -28px -62px auto',
  cursor: 'pointer',
  zIndex: 2,
  fill: theme.palette.common.white,
  background: theme.palette.grey[500],
  borderRadius: '50%'
}));

export const SidebarOpenIcon = styled(KeyboardArrowRightRounded)(({ theme }) => ({
  margin: '15px -28px -62px auto',
  cursor: 'pointer',
  zIndex: 2,
  fill: theme.palette.common.white,
  background: theme.palette.grey[500],
  borderRadius: '50%'
}));

export const DesktopChildrenContainer = styled(StyledContainer)({
  height: '100%',
  overflow: 'auto',
  borderRadius: 0,
  padding: '0 24px',
  minHeight: '100vh',
  maxWidth: '3000px'
});

export const SidebarButton = styled(StyledButton)({
  gap: '8px',
  height: '40px',
  minWidth: '24px',
  marginTop: 'auto'
});

export const SidebarSeparator = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '1.5px',
  marginTop: '16px',
  background: theme.palette.grey[300]
}));

export const LayoutLoader = styled(CircularProgress)({
  margin: '30vh auto'
});
